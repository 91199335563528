<div *ngIf="drankkaart" class="box">
  <div *ngFor="let rubriek of drankkaart.rubrieken" class="rubriek">
    <div class="titel">{{ rubriek.titel }}</div>
    <div class="content">
      <div *ngFor="let subrubriek of rubriek.items">
        <p class="subtitel">{{ subrubriek.titel }}</p>
        <app-template-drankkaart-item
          *ngFor="let item of subrubriek.items"
          [item]="item">
        </app-template-drankkaart-item>
      </div>
    </div>
  </div>
  <div *ngIf="drankkaart.volume" style="text-align: right">{{drankkaart.volume}}</div>
</div>
