<div class="kaartitem">
  <div class="titel">
    <span *ngIf="item.titel">{{item.titel}}</span>
  </div>
  <div class="prijs">
    <span class="drank_prijs_fles" *ngIf="item.fles.prijs">
      {{item.fles.prijs | currency: 'EUR'}} &nbsp; {{item.fles.volume}} /fles<br />
    </span>
    <span class="drank_prijs_glas" *ngIf="item.glas.prijs">
      {{item.glas.prijs | currency: 'EUR'}} <span *ngIf="item.glas.volume"> /glas</span> <br />
    </span>
  </div>
  <div class="omschrijving" *ngIf="item.omschrijving1">
    <span *ngIf="item.omschrijving1">{{item.omschrijving1}}<br /></span>
    <span *ngIf="item.omschrijving2">{{item.omschrijving2}}<br /></span>
    <span *ngIf="item.omschrijving3">{{item.omschrijving3}}<br /></span>
    <span *ngIf="item.omschrijving4">{{item.omschrijving4}}<br /></span>
  </div>
</div>
