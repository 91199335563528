<div class="text_align_center"  style="background: none; font-size: x-large">

<!--  <p><strong>Uitzonderlijk gesloten op maandag 6 december</strong></p>-->



    <strong>5-6-7-8 januari is Amu er even tussenuit.</strong>
  <br/>
  <p>
    Zaterdag 18 Januari is het <strong>Turnhout by Night</strong>, die avond werken wij in twee shift zodat u de Turnhout by Night wandeling kan combineren met een heerlijke culinaire ervaring.
    16u.00  tot 18u.45
    19u.00 tot …
  </p>




<!--  <p>Aangepaste openingsuren tijdens de feestdagen</p>-->
<!--  <ul>-->
<!--    <li>24 en 25 december enkel take away op bestelling</li>-->
<!--    <li>26 t.e.m. 30 december: 17u30 tot 00u00</li>-->
<!--    <li>31 december enkel take away op bestelling</li>-->
<!--    <li>1 januari gesloten</li>-->
<!--    <li>2 t.e.m 7 januari: 17u30 tot 00u00</li>-->
<!--  </ul>-->


</div>
