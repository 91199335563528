<div class="kaartitem">


  <div style="font-size: larger; "  class="item">

    <span class="wijn_chateau" style="text-align: left" *ngIf="item.titel">{{item.titel}}</span>

  </div>
  <div *ngIf="item.omschrijving1" class="item">
    <span class="" *ngIf="item.omschrijving1">{{item.omschrijving1}}<br/></span>
  </div>


  <div class="prijs">
    <span class="drank_prijs_fles" *ngIf="item.fles.prijs">{{item.fles.prijs  | currency:'EUR'}} &nbsp; {{item.fles.volume}} /fles<br/></span>
    <span class="drank_prijs_glas" *ngIf="item.glas.prijs">{{item.glas.prijs | currency:'EUR'}} <span *ngIf="item.glas.volume"> /glas</span> <br/></span>
  </div>
<br/>
  <br/>
</div>
